<template>
  <div class="mt-0">
    <component-layout :title="project.name">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto" dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
            
          <v-list>
            <v-list-item>
              <v-list-item-title v-ripple @click="editProject">
                EDIT</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <div class="my-10">
        <div class="row align-center mx-auto">
          <v-icon class="primary-color mr-2">mdi-calendar-month</v-icon>
          <div>
            <span class="primary-color project-name-label">Project Date</span>
            <h4>{{ project.start_date }} to {{ project.end_date }}</h4>
          </div>
        </div>

        <div class="row align-center mt-10 mx-auto">
          <div class="row mx-auto">
            <v-icon class="primary-color mr-2">mdi-priority-high</v-icon>
            <div>
              <span class="primary-color project-name-label">Priority</span>
              <h4>{{ project.priority }}</h4>
            </div>
          </div>

          <div class="row mt-0 mx-auto">
            <v-icon class="primary-color mr-2">mdi-list-status</v-icon>
            <div>
              <span class="primary-color project-name-label">Status</span>
              <div>
                <v-chip
                  v-if="project.status == 'Completed'"
                  class="ma-2"
                  color="green"
                  text-color="white"
                >
                  {{ project.status }}
                </v-chip>
                <v-chip
                  v-else-if="project.status == 'Active'"
                  class="ma-2"
                  color="primary"
                  text-color="white"
                >
                  {{ project.status }}
                </v-chip>
                <v-chip
                  v-else
                  class="ma-2"
                  color="red"
                  text-color="white"
                >
                  {{ project.status }}
                </v-chip>
              </div>
              <!-- <h4>{{ project.status }}</h4> -->
            </div>
          </div>
        </div>
        <div class="my-10 mx-auto">
         <v-icon class="primary-color mr-2">mdi-account-group</v-icon
            > <span class="primary-color pb-2 project-name-label"
            >Assigned Users:</span
          >

          <template>
            <div>
              <v-combobox
                v-model="project.assignees"
                :items="project.assignees"
                chips
                label="Assigned Users"
                multiple
                solo
                disabled
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    @click="select"
                  >
                    <strong>{{ item.user.full_name }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </template>
        </div>
      </div>
      <v-dialog v-model="showEditForm" max-width="800px">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <CreateProject :edit="project" @cancel="cancel"/>
            </div>
          </div>
        </div>
      </v-dialog>
    </component-layout>
  </div>
</template>
<script>
import util from '@/util/util.js'
import Rest from "@/components/shared/rest";
import componentLayout from "@/components/component-layout.vue";
import CreateProject from "@/components/Project/CreateProject.vue";
export default {
  components: { componentLayout, CreateProject },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      project: {},
      showEditForm: false,
    };
  },
  mounted() {
    this.getProject();
    util.event.$on("reload-resource", () => {
      console.log('say');
      this.showEditForm = false;
      this.getProject();
    });
  },
  methods: {
    editProject() {
      this.showEditForm = true;
    },
    getProject() {
      Rest.getSingle.call(this, {
        resource: "project",
        id: this.id,
        prefix: "tm",
      });
      this.assignees = this.project.assignees;
    },
    cancel() {
      this.showEditForm = false;
    },
  },
  watch: {
    "$props.id": function () {
      this.getProject();
    },
  },
};
</script>

<style lang="scss">
.project-name-label{
  color: #949494!important;
  font-weight: 800;
  font-size: 14px;
}
.project-name-title {
  background: #1d2b58;
  color: #fff;
  padding: 10px 10px !important;
}
.primary-color {
  color: #1d2b58;
}
</style>
