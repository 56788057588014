<template>
  <div>
    <component-layout title="create board">
      <v-form @submit.prevent="submit" v-model="isValid" ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-bind="basic"
              label="Name"
              value="Name"
              v-model="data.name"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-bind="basic"
              label="Project"
              :value="projectName"
              outlined
              disabled
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-menu
              ref="menu"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.start_date"
                  label="Start Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="{ ...attrs, ...basic }"
                  v-on="on"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.start_date"
                no-title
                scrollable
                @change="menu1 = false"
              />
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.end_date"
                  label="End Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="{ ...attrs, ...basic }"
                  v-on="on"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.end_date"
                no-title
                scrollable
                @change="menu2 = false"
              />
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-select
              v-bind="basic"
              v-model="data.status"
              :items="statusOptions"
              label="Status"
              :rules="[rules.required]"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-bind="basic"
              outlined
              name="input-7-4"
              label="Description"
              v-model="data.description"
            ></v-textarea>
          </v-col>
          <v-col>
            <div class="mt-5 d-flex justify-center">
              <v-btn
                v-bind="basic"
                class="col-5 mr-4 submit-btn"
                type="submit"
                :loading="loading"
                :disabled="!isValid"
              >
                Save
              </v-btn>
              <v-btn class="col-5 cancel-btn" @click="cancel"> Cancel </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "@/components/component-layout";
export default {
  components: { componentLayout },
  mounted() {
    if (this.$props.project) {
      this.projectName = this.$props.project.name;
    }
    this.fetchStatusOptions();
  },
  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      rules: util.validation,
      basic: util.input.basic,
      statusOptions: [],
      data: {},
      menu1: false,
      menu2: false,
      modal: false,
      isValid: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      projectName: "",
    };
  },
  methods: {
    fetchStatusOptions() {
      util.http({
        url: 'filters/tm-board',
        method: 'get',
      }).then(resp => {
        this.statusOptions = resp.data.data;
      });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.data.project_id = this.$props.project.id;
        util
          .http({
            url: "/tm/board",
            method: "post",
            data: this.data,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Board Created");
              this.$refs.form.reset();
              this.$emit("reloadResource", true);
              // this.$router.push({
              //   name: "ProjectDetail",
              //   params: { id: this.$route.params.project.id },
              // });
            } else {
              util.notify(0, res.data.errors[0]);
            }
          })
          .catch();
      }
    },
    cancel() {
      this.$emit("cancel", true);
    },
  },
};
</script>

<style scoped>
.submit-button {
  background: #1976d2;
  border-color: #1976d2;
}

.cancel {
  border-color: #1976d2;
}
</style>
